import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { ROUTES } from 'app/config/routes';

const ListFulfillment = Loadable(lazy(() => import('./ListFulfillment')));
const CreateFulfillment = Loadable(lazy(() => import('./CreateFulfillment')));
const DetailFulfillment = Loadable(lazy(() => import('./DetailFulfillment')));

const fulfillmentRoutes = [
  { path: ROUTES.fulfillment.index, element: <ListFulfillment />, auth: authRoles.admin },
  { path: ROUTES.fulfillment.create, element: <CreateFulfillment />, auth: authRoles.admin },
  { path: ROUTES.fulfillment.detail + '/:id', element: <DetailFulfillment />, auth: authRoles.admin },
];

export default fulfillmentRoutes;
