import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { ROUTES } from 'app/config/routes';

const ListStores = Loadable(lazy(() => import('./ListTemplate')));
const CreateTemplate = Loadable(lazy(() => import('./CreateTemplate')));
const DetailTemplate = Loadable(lazy(() => import('./DetailTemplate')));

const storeRoutes = [
  { path: ROUTES.template.index, element: <ListStores />, auth: authRoles.admin },
  { path: ROUTES.template.create, element: <CreateTemplate />, auth: authRoles.admin },
  { path: ROUTES.template.detail + "/:id", element: <DetailTemplate />, auth: authRoles.admin },
];

export default storeRoutes;
