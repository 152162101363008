import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

const ConfirmationDialog = ({ open, close, text, title = 'Confirm', onConfirm }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: 'grey' }}>
            {text.message} {text.templateName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>No</Button>
          <Button onClick={() => onConfirm(true)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmationDialog;
