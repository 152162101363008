export const ROUTES = {
  home: '/',
  login: '/session/signin',
  user: {
    index: '/user',
    create: '/user/create'
  },
  fulfillment: {
    index: '/fulfillment',
    create: '/fulfillment/create',
    detail: '/fulfillment/detail',
  },
  store: {
    index: '/store',
    create: '/store/create'
  },
  dashboard: {
    index: '/dashboard/default',
  },
  charts: {
    index: '/charts/echarts',
  },
  template: {
    index: '/download-template',
    create: '/download-template/create',
    detail: '/download-template/detail',
  },
  order: {
    index: '/order',
    detail: '/order/detail',
    fulfillments: '/order/bulk-fulfillments',
  }

};
