import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { ROUTES } from 'app/config/routes';

const ListStores = Loadable(lazy(() => import('./ListStores')));
const CreateStore = Loadable(lazy(() => import('./CreateStore')));

const storeRoutes = [
  { path: ROUTES.store.index, element: <ListStores />, auth: authRoles.supplier },
  { path: ROUTES.store.create, element: <CreateStore />, auth: authRoles.admin },
];

export default storeRoutes;
