import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import { ROUTES } from 'app/config/routes';
import { lazy } from 'react';

const AppEchart = Loadable(lazy(() => import('./echarts/AppEchart')));

const chartsRoute = [{ path: ROUTES.charts.index, element: <AppEchart />, auth: authRoles.editor }];

export default chartsRoute;
