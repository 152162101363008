import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import storeRoutes from './views/stores/StoreRoutes';
import orderRoutes from './views/orders/orderRoutes';
import userRoutes from './views/user/UserRoute';
import templateRoutes from './views/template/TemplateRoute';
import fulfillmentRoutes from './views/fulfillment/FulfillmentRoute';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import { ROUTES } from './config/routes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...storeRoutes,
      ...templateRoutes,
      ...orderRoutes,
      ...userRoutes,
      ...fulfillmentRoutes,
    ],
  },
  ...sessionRoutes,
  { path: ROUTES.home, element: <Navigate to="store" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
