import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { ROUTES } from 'app/config/routes';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const dashboardRoutes = [
  { path: ROUTES.dashboard.index, element: <Analytics />, auth: authRoles.admin },
];

export default dashboardRoutes;
