import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { ROUTES } from 'app/config/routes';

const ListUser = Loadable(lazy(() => import('./ListUser')));
const CreateUser = Loadable(lazy(() => import('./CreateUser')));

const storeRoutes = [
  { path: ROUTES.user.index, element: <ListUser />, auth: authRoles.admin },
  { path: ROUTES.user.create, element: <CreateUser />, auth: authRoles.admin },
];

export default storeRoutes;
