import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { ROUTES } from 'app/config/routes';

const ListStores = Loadable(lazy(() => import('./ListOrders')));
const DetailStores = Loadable(lazy(() => import('./DetailOrder')));
const BulkFulfillments = Loadable(lazy(() => import('./BulkFulfillments')));

const storeRoutes = [
  { path: ROUTES.order.index, element: <ListStores />, auth: authRoles.admin },
  { path: ROUTES.order.detail + "/:id", element: <DetailStores />, auth: authRoles.admin },
  { path: ROUTES.order.fulfillments, element: <BulkFulfillments />, auth: authRoles.admin },
];

export default storeRoutes;
